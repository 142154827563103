import ImageFooter from "../Images/LOGO-footer.png";
export default function Footer() {
  return (
    <>
      <footer>
        <img src={ImageFooter} alt="logo Kasa" />
        <p>© 2020 Kasa. All rights reserved</p>
      </footer>
    </>
  );
}
